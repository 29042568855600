import React from 'react';

import SEO from '../components/seo';

const NotFoundPage = () => (
  <div style={{ height: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
    <SEO title="404: Page introuvable" />

    <h1 style={{ fontSize: '2.25rem', fontWeight: 'bold' }}>404</h1>

    <h4 style={{ fontSize: '1.125rem', marginTop: '1.5rem' }}>La page que vous cherchez est introuvable!</h4>
    
    <p style={{ lineHeight: '1.8' }}>Vous avez probablement mal saisi l'adresse ou la page a été déplacée</p>
  </div>
);

export default NotFoundPage;

